import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";

const CircleTooltip = ({ text, link }: { text: string; link: string }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const triggerRef = useRef<HTMLAnchorElement>(null);

  // Handle mouse and touch events
  const showTooltip = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setTooltipStyle({
        top: rect.bottom + 8, // Position below the trigger with a small offset
        left: rect.left + rect.width / 2 , // Center horizontally
        transform: "translateX(-50%)", // Adjust for centering
      });
    }
    setIsVisible(true);
  };

  const hideTooltip = () => setIsVisible(false);

  return (
    <div
      className="relative group pr-3"

    >
      {/* Circle with transparent background and orange border */}
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="w-5 h-5 flex items-center justify-center border-2 border-orange-500 text-orange-500 text-sm rounded-full cursor-pointer hover:text-blue-500 hover:border-blue-500"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onTouchStart={showTooltip} // Touch functionality for mobile
        onTouchEnd={hideTooltip} // Hide tooltip after touch ends
        ref={triggerRef}
      >
        ?
      </a>

      {/* Render the tooltip outside the sidebar using a portal */}
      {isVisible &&
        createPortal(
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed z-50 min-w-3xs max-w-3xs z-9999"
            style={{
              ...tooltipStyle,
            }}
          >
            <div className="bg-admonition-warning-orange border-admonition-warning-orange text-bright-white text-sm font-medium p-3 rounded-md shadow-md whitespace-normal">
              {text}
            </div>
          </a>,
          document.body // Render the tooltip in the body
        )}
    </div>
  );
};

export default CircleTooltip;