import React, { useContext } from "react";
import { navbarButtons } from "../const";
import { useSiteMetadataVersions } from "../queries/useSiteMetadataVersions";
import { buildPathIndexUrl } from "../utils/buildAppUrl";
import NavbarButton from "./navbar/NavbarButton";
import ToggleNightMode from "./navbar/ToggleNightMode";
import { useLocation } from "../contexts/useLocation";
import { isSearchScreen } from "../utils/screenRouteChecks";
import { isHomeScreen } from "../utils/screenRouteChecks";

import SearchBox from "./SearchBox";
import { ResponsiveContext } from "../contexts/ResponsiveContext";

const Navbar = ({ version }: { version?: string }) => {
  const { pathname } = useLocation();
  const { latestVersion } = useSiteMetadataVersions();
  const { responsiveState } = useContext(ResponsiveContext);

  return (
    <aside className={`flex flex-row sticky top-0 z-30 ${isHomeScreen(pathname) ? 'bg-very-dark-grey' : 'bg-bright-white '} dark:bg-very-dark-grey w-full`}>
      <div className={`${isHomeScreen(pathname) ? 'pl-0 mb-2' : 'pl-6 sm:pl-0'} pl-6 sm:pl-0 w-full h-full shrink sm:shrink-0 flex xl:flex-row flex-col lg:justify-between items-center`}>
        {/* Links to sections. */}
        <div className="flex-auto shrink-0 lg:w-auto w-full">
          <div className=" h-14 flex-wrap flex items-center justify-center">
            <div className="flex flex-row justify-center">
              {navbarButtons.map(({ path, label }) => (
                <div key={path} className="px-1 sm:px-2 w-20 sm:w-28 ">
                  <NavbarButton
                    key={path}
                    path={path}
                    label={label}
                    route={buildPathIndexUrl({
                      latestVersion,
                      version,
                      path,
                    })}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Search box and light/dark toggle. */}
        <div className="flex items-center lg:w-auto w-full justify-center lg:justify-end h-full pr-10">
          <div className="flex flex-row justify-center items-center w-80">
            <div>
              {/* Search box on search results page */}
              {isSearchScreen(pathname) &&
                !responsiveState.isMenuActive &&
                !responsiveState.isSidebarActive && (
                  <SearchBox hideResults={true} isSearchScreen={true} />
                )}
              {/* Search box on all other pages */}
              {!isSearchScreen(pathname) && <SearchBox />}
            </div>
            <div>
              <ToggleNightMode />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Navbar;
