import _ from "lodash";
import { Guide, isGuide } from "../types/types";
import {
  c,
  eof,
  firstOf,
  fromPredicate,
  map,
  optional,
  Parser,
  string,
} from "./parser";
import { ParserError } from "./parser.error";

type Optional = {
  language?: string;
  version?: string;
  title?: string;
};

type WithGuide = {
  guide: Guide;
  permalink?: string;
};

type WithPermalink = {
  guide?: Guide;
  permalink: string;
};

export type Parsed = Optional & (WithGuide | WithPermalink);

function isVersion(v: string): boolean {
  return v === "latest" || v === "cloud" || /^[0-9]+\.[0-9]+(\.[0-9]+)?$/.test(v);
}

function isNumber(v: string): boolean {
  return !isNaN(+v);
}

function isLanguage(v: string): boolean {
  return !isNumber(v) && v.length === 2;
}

// TODO: somehow combine regexp for permalink here and in the parseDocUrl
function isPermalink(v: string): boolean {
  return /^[0-9a-zA-Z+/]{6}$/.test(v);
}

const language: Parser<string> = fromPredicate(isLanguage);
const version: Parser<string> = fromPredicate(isVersion);
const guide: Parser<Guide> = fromPredicate(isGuide);
const permalink: Parser<string> = fromPredicate(isPermalink);
const title: Parser<string> = string;

const justAnyValid: Parser<Parsed> = firstOf<Parsed>(
  // Parser for "original".
  map(
    c(
      optional(language),
      optional(version),
      guide,
      optional(permalink),
      optional(title),
      eof
    ),
    ([language, version, guide, permalink, title]) => ({
      language,
      version,
      guide,
      permalink,
      title,
    })
  ),
  // Parser for "current".
  map(
    c(
      optional(language),
      optional(version),
      optional(guide),
      permalink,
      optional(title),
      eof
    ),
    ([language, version, guide, permalink, title]) => ({
      language,
      version,
      guide,
      permalink,
      title,
    })
  )
);

export function parseAppUrl(url: string): Parsed {
  const slugs = _.trim(url, "/ ").split("/");
  const input = {
    original: slugs,
    current: slugs,
  };

  const r = justAnyValid(input);
  if (r.output.type === "error") {
    throw new ParserError("Cannot parse app url.");
  }
  return r.output.parsed;
}
