import { buildSearchBox, SearchEngine } from "@coveo/headless";
import React, { useContext, useEffect, useState } from "react";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { SearchBoxContext } from "../contexts/SearchBoxContext";
import { useLocation } from "../contexts/useLocation";
import { headlessEngine } from "../coveo/Engine";
import { navigate } from "gatsby";
import { ResponsiveContext } from "../contexts/ResponsiveContext";
import { isHomeScreen } from "../utils/screenRouteChecks";
import {
  loadSearchActions,
  loadPaginationActions,
  loadQueryActions,
  loadSearchAnalyticsActions,
} from "@coveo/headless";

type Props = {
  hideResults?: boolean;
  isSearchScreen?: boolean;
};

// Type guard to check if headlessEngine is an actual SearchEngine
const isSearchEngine = (engine: any): engine is SearchEngine =>
  "dispatch" in engine && typeof engine.dispatch === "function";

// Use the type guard before creating the search box
const coveoSearchBox = isSearchEngine(headlessEngine)
  ? buildSearchBox(headlessEngine)
  : null;

const SearchBox = ({ isSearchScreen = false }: Props) => {
  const location = useLocation();
  const { pathname } = useLocation();

  const [controllerState, setControllerState] = useState(
    coveoSearchBox ? coveoSearchBox.state : null,
  );
  const { responsiveState, dispatch } = useContext(ResponsiveContext);
  const { setShowSearchResult } = useContext(SearchBoxContext);

  // Only load actions if headlessEngine is a valid SearchEngine
  const searchActions = isSearchEngine(headlessEngine)
    ? loadSearchActions(headlessEngine)
    : null;
  const paginationActions = isSearchEngine(headlessEngine)
    ? loadPaginationActions(headlessEngine)
    : null;
  const queryActions = isSearchEngine(headlessEngine)
    ? loadQueryActions(headlessEngine)
    : null;
  const analyticsActions = isSearchEngine(headlessEngine)
    ? loadSearchAnalyticsActions(headlessEngine)
    : null;

  const submitSearch = () => {
    if (!coveoSearchBox || !isSearchEngine(headlessEngine)) {
      console.warn(
        "Coveo search engine is not initialized. Search cannot be performed.",
      );
      return;
    }
    if (responsiveState.isMenuActive) {
      dispatch({ type: "toggleMenu" });
    }
    if (!isSearchScreen) {
      navigate("/search");
    } else {
      setShowSearchResult(true);
      const query = coveoSearchBox.state.value;
      headlessEngine.dispatch(queryActions!.updateQuery({ q: query }));

      headlessEngine.dispatch(paginationActions!.updatePage(1));
      headlessEngine.dispatch(
        searchActions!.executeSearch(analyticsActions!.logSearchboxSubmit()),
      );
    }
  };

  useEffect(() => {
    if (!coveoSearchBox) return;
    if (location.pathname.includes("/search")) {
      submitSearch();
    }
    return coveoSearchBox.subscribe(() =>
      setControllerState(coveoSearchBox.state),
    );
  }, []);

  return (
    <div className="mb-1 mt-[6px] p-0 border-transparent rounded-2xl inline-block relative">
      <style>
        {`
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 1000px ${isHomeScreen(pathname) ? '#3C3A42' : '#F1F0F4'} inset !important;
          -webkit-text-fill-color: ${isHomeScreen(pathname) ? '#97A3B7' : '#1E1E1E'} !important;
          transition: background-color 5000s ease-in-out 0s; /* Prevent the background color from changing */
        }

        .dark input:-webkit-autofill,
        .dark input:-webkit-autofill:hover, 
        .dark input:-webkit-autofill:focus,
        .dark input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 1000px ${isHomeScreen(pathname) ? '#3C3A42' : '#3C3A42'} inset !important;
          -webkit-text-fill-color: ${isHomeScreen(pathname) ? '#97A3B7' : '#97A3B7'} !important;
          transition: background-color 5000s ease-in-out 0s; /* Prevent the background color from changing */
        }
      `}
      </style>
      <form
        className="flex items-center max-w-xs mx-auto"
        onSubmit={(event) => {
          event.preventDefault(); // Prevent the default form submission
          submitSearch(); // Call the submit function
        }}
      >
        <input
          type="text"
          id="simple-search"
          autoComplete="off"
          className={`h-8 border-none outline-none ${isHomeScreen(pathname) ? '!bg-medium-dark-grey !text-soft-blue-grey' : '!bg-very-light-grey dark:!bg-medium-dark-grey'} border border-transparent !text-medium-dark-grey dark:!text-soft-blue-grey text-sm rounded-2xl block w-full pl-3 pr-10 py-2`}
          placeholder="Search..."
          value={controllerState?.value || ""}
          onChange={(e) => coveoSearchBox?.updateText(e.target.value)} // Only call if not null
        />
        <div className={`${isHomeScreen(pathname) ? 'text-bright-white' : 'dark:text-bright-white text-very-dark-grey'} absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none`}>
          {/* @ts-ignore */}
          <SearchOutlined />
        </div>
      </form>
    </div>
  );
};

export default SearchBox;
