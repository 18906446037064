import axios from "axios";

const COVEO_ORGANISATION_ID = process.env.COVEO_ORGANISATION_ID || null;
const COVEO_ACCESS_TOKEN = process.env.COVEO_ACCESS_TOKEN || null;
const COVEO_SOURCE = process.env.COVEO_SOURCE || null;

export type AllFacetsResult = {
  numberOfResults: number;
  value: string;
};

export type GetAllFacetsProps = {
  fields: string;
  onSuccess(results: AllFacetsResult[]): void;
};

export function getAllFacets({ fields, onSuccess }: GetAllFacetsProps): void {
  const data = {
    field: `@${fields}`,
    pipeline: "Customer",
    cq: `@source=="${COVEO_SOURCE}"`,
    maximumNumberOfValues: 100,
  };
  axios.defaults.headers.common = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${COVEO_ACCESS_TOKEN}`,
    Accept: "*/*",
    // This has been commented out, as it is not allowed anymore and should be set by browser.
    // When this is set, it will be ignored.
    // TODO - Double check that this does not result in unintended behaviour.
    //"Accept-Encoding": "gzip, deflate, br",
  };
  axios
    .post<{ values: AllFacetsResult[] }>(
      "https://platform.cloud.coveo.com/rest/search/v2/values?organizationId=" +
        COVEO_ORGANISATION_ID,
      data,
    )
    .then((result) => {
      if (result && result.status === 200) {
        // Keep only facet versions of form major.minor (not major.minor.patch).
        // Also include cloud version for new naming schema.
        onSuccess(
          result.data.values.filter((facet) => {
            return (
              /^\d+\.\d+$/.test(facet.value) ||
              facet.value === "cloud" ||
              facet.value === "Cloud"
            );
          }),
        );
      }
    })
    .catch((error) => {
      console.error("Error fetching facets:", error);
    });
}
