import { MainPath } from "../types/types";

// TODO: query them from gatsby?
const knownUrls = ["/", "/404.html", "/[...]/", "/404/", "/search/"];

// TODO: make it more robust
export function parseCurrentPath(pathname: string): MainPath | null {
  if (pathname.includes("/user_guide")) {
    return "user_guide";
  }
  if (pathname.includes("/admin_guide")) {
    return "admin_guide";
  }
  if (pathname.includes("/api_guide")) {
    return "api_guide";
  }
  if (pathname.includes("/release_notes")) {
    return "release_notes";
  }
  if (knownUrls.includes(pathname)) {
    return null;
  }
  console.warn("Unknown url, couldn't parse CurrentPath.", pathname);
  return null;
}
