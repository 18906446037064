import { graphql, useStaticQuery } from "gatsby";
import { SiteMetadata } from "../../gatsby-configts";

export const useSiteMetadataVersions = () => {
  const {
    site: {
      siteMetadata: { versions, latestVersion, cloudVersion },
    },
  } = useStaticQuery<{
    site: { siteMetadata: Pick<SiteMetadata, "versions" | "latestVersion" | "cloudVersion"> };
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            versions
            latestVersion
            cloudVersion
          }
        }
      }
    `
  );
  return { versions, latestVersion, cloudVersion };
};
