import React, { useContext, useEffect, useState } from "react";
import { ResponsiveContext } from "../contexts/ResponsiveContext";
import ResponseNavbar from "./navbar/responsiveNavbar";
import TocMenu from "./toc/TocMenu";
import { useLocation } from "../contexts/useLocation";
import { useSiteMetadataVersions } from "../queries/useSiteMetadataVersions";
import { SearchBoxContext } from "../contexts/SearchBoxContext";
import { useDocTrace } from "./layout.utils";
import { DocOverview } from "../templates/appPage";
import BreadCrumbList from "./breadCrumbList";

type Props = {
  children: React.ReactNode;
  title: string | undefined;
  root: DocOverview | undefined;
  id: string | undefined;
  version: string;
  permalink: string;
  categoryVersions: string[];
};

const MainContent = ({ children, title, root, id }: Props) => {
  const location = useLocation();
  const { latestVersion } = useSiteMetadataVersions();
  const { responsiveState, dispatch } = useContext(ResponsiveContext);
  const { setShowSearchResult } = useContext(SearchBoxContext);
  const [tocElements, setTocElements] = useState<Element[]>([]);
  const docTrace = useDocTrace({ docs: root ? [root] : [], id });
  const hasRoot = root !== undefined;
  const backdropToggle = () => {
    if (responsiveState.isSidebarActive) {
      dispatch({ type: "toggleSidebar" });
    }

    if (responsiveState.isMenuActive) {
      dispatch({ type: "toggleMenu" });
    }
  };

  function isLandingPage(): boolean {
    return location.pathname === "/";
  }

  useEffect(() => {
    setTocElements([]);
    const headingElements = Array.from(document.querySelectorAll("h2,h3,h4"));
    setTocElements(headingElements);
  }, [location.pathname]);

  return (
    <div className="flex flex-col pb-0 md:pt-0 flex-shrink">
      <div
        className="flex flex-row overflow-x-hidden relative"
        onClick={() => setShowSearchResult(false)}
      >
        {(responsiveState.isSidebarActive || responsiveState.isMenuActive) && (
          <div
            className="absolute w-full h-screen z-30 bg-backdrop"
            onClick={backdropToggle}
          />
        )}
        <ResponseNavbar />

        <div
          className="flex flex-col w-full p-0 main overflow-x-hidden pt-1 pl-2 md:pl-6 pr-2 md:pr-8 dark:bg-very-dark-grey"
        >
          {hasRoot && !isLandingPage() && (
            <BreadCrumbList
              docTrace={docTrace}
              latestVersion={latestVersion}
              id={id}
            />
          )}
          {title && <h1 className="title">{title}</h1>}
          <div className="break-words">{children}</div>
        </div>
        {hasRoot && !isLandingPage() && (
          <TocMenu elements={tocElements} />
        )}
      </div>
    </div>
  );
};
export default MainContent;
