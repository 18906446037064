// This is called from getUrlForChangedVersion() in components/sidebar/sidebar.utils.ts.
//
export function checkDocExistenceForVersion(
  version: string,
  permalink: string,
  title: string,
  versionLookup: Record<string, { versions: string[]; titles: string[] }>, // Use existing structure
): [boolean, string | null] {
  const versions: string[] = versionLookup[permalink]?.versions || [];
  const titles: string[] = versionLookup[permalink]?.titles || [];

  if (versions.includes(version)) {
    const versionIndex: number = versions.indexOf(version);
    const correctTitle: string = titles[versionIndex];

    if (title === correctTitle) {
      // If the title matches, return true and no URL
      return [true, null];
    } else {
      // If the title does not match, return the the updated title
      return [true, correctTitle];
    }
  }

  // If the permalink does not exist for the selected version, return false and no URL
  return [false, null];
}
