// Trauncate a version string.
//
// Will return a version string as major.minor, stripping off patch if present.
//
// 4.3 -> 4.3
// 4.3.2 -> 4.3
//
function getMajorMinor(version: string): string {
  const match = version.match(/^(\d+\.\d+)(\.\d+)?$/);
  return match ? match[1] : version;
}

export function normalizeVersion(version = "cloud", latestVersion: string) {
  return version === "latest" ? latestVersion : getMajorMinor(version);
}
