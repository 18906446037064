import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Switch } from "antd";
import { ThemeContext } from "../../contexts/ThemeContext";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { navbarButtons } from "../../const";
import NavbarButton from "./NavbarButton";
import MySearchBox from "../SearchBox";
import { buildPathIndexUrl } from "../../utils/buildAppUrl";
import { useSiteMetadataVersions } from "../../queries/useSiteMetadataVersions";
import { useCurrentPath } from "../../contexts/useCurrentPath";
import { footerDummyData } from "../footer/footer.utils";
import FooterButtonResponsive from "../footer/footerButtonResponsive";

const ResponsiveNavbar = () => {
  const { latestVersion } = useSiteMetadataVersions();
  const { dark, toggleDark } = useContext(ThemeContext);
  const { responsiveState, dispatch } = useContext(ResponsiveContext);
  const currentPath = useCurrentPath();

  return (
    <div
      className={`navbar-responsive-container ${
        responsiveState.isMenuActive
          ? "active-menu-animation"
          : responsiveState.menuToggleCalled
          ? "deactive-menu-animation"
          : "left-full"
      } sm:w-6/12 flex lg:hidden bg-primary-v2 dark:bg-very-dark-grey`}
    >
      <span className="close-icon">
        <AiOutlineClose
          size={20}
          className="close-icon-svg"
          onClick={() => dispatch({ type: "toggleMenu" })}
        />
      </span>
      <span className="w-full mt-6">
        <MySearchBox />
      </span>
      <div className="flex flex-col justify-start mt-4">
        {navbarButtons.map(({ path, label }) => (
          <div
            className={`m-2 text-center items-center ${
              path != currentPath && "border border-color-primary-dark rounded"
            }`}
            key={path}
          >
            <NavbarButton
              key={path}
              path={path}
              label={label}
              route={buildPathIndexUrl({
                latestVersion,
                path,
              })}
            />
          </div>
        ))}
      </div>
      <span className="text-white text-base font-bold my-2 text-center">
        About Domino
      </span>
      <div className="flex flex-col mb-10 ">
        {footerDummyData.map((data, index) => (
          <FooterButtonResponsive data={data} key={index} />
        ))}
      </div>
      <div className="flex switch-styles">
        <label
          htmlFor="flexSwitchCheckDefault"
          className="text-white text-sm mr-2 switch-label"
        >
          {dark ? "dark" : "light"}
        </label>
        <div className="flex justify-center mx-5">
          <div id="flexSwitchCheckDefault">
            <Switch checked={dark} onChange={toggleDark} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveNavbar;
