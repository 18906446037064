import React, { createContext, FC, useState } from "react";
import { FacetValue } from "@coveo/headless/dist/definitions/controllers/facets/facet/headless-facet";

type SearchBoxContextType = {
  selectedVersion: FacetValue | undefined;
  setSelectedVersion: (version: FacetValue | undefined) => void;
  showSearchResult: boolean;
  setShowSearchResult: (show: boolean) => void;
};

const initialState = {
  selectedVersion: undefined,
  showSearchResult: false,
};

export const SearchBoxContext = createContext<SearchBoxContextType>({
  selectedVersion: initialState.selectedVersion,
  setSelectedVersion: () => {},
  showSearchResult: initialState.showSearchResult,
  setShowSearchResult: () => {},
});
// @ts-ignore
export const SearchBoxResultShowProvider: FC = ({ children }) => {
  const [selectedVersion, setSelectedVersion] = useState<FacetValue | undefined>(initialState.selectedVersion);
  const [showSearchResult, setShowSearchResult] = useState(initialState.showSearchResult);

  return (
    <SearchBoxContext.Provider
      value={{
        selectedVersion,
        setSelectedVersion,
        showSearchResult,
        setShowSearchResult,
      }}
    >
      {children}
    </SearchBoxContext.Provider>
  );
};
