import { checkDocExistenceForVersion } from "../../utils/checkDocExistenceForVersion";
import { buildAppUrl, buildGuideIndexUrl } from "../../utils/buildAppUrl";
import { Guide } from "../../types/types";

// This is called from changeSelectedVersion() in ./components/sidebar/sidebar.tsx.
export function getUrlForChangedVersion({
  version,
  latestVersion,
  permalink,
  title,
  currentPath,
  versionLookup,
}: {
  version: string;
  latestVersion: string;
  permalink: string;
  title: string;
  currentPath: Guide;
  versionLookup: any;
}): string {
  const [check, correctedTitle]: [boolean, string | null] =
    checkDocExistenceForVersion(version, permalink, title, versionLookup);

  if (check) {
    if (correctedTitle) {
      // If the permalink exists but the title does not match, redirect to the correct URL
      const url: string = buildAppUrl({
        version,
        latestVersion,
        permalink,
        title: correctedTitle,
        path: currentPath,
      });
      return url;
    } else {
      // If the permalink and title match, build the URL using the existing function
      const url: string = buildAppUrl({
        version,
        latestVersion,
        permalink,
        title,
        path: currentPath,
      });
      return url;
    }
  } else {
    // If the permalink does not exist for the selected version, fall back to the guide index
    return buildGuideIndexUrl({
      guide: currentPath,
      latestVersion,
      version,
    });
  }
}
