import { Link } from "gatsby";
import React from "react";

type Props = {
  title: string;
  url: string;
  active: boolean;
  isSearchItem?: boolean;
};

const BreadCrumb = ({ title, url, active, isSearchItem = false }: Props) => {
  const baseTextClass = "text-medium-dark-grey dark:text-soft-blue-grey text-xs inline-flex items-center";
  const breadcrumbClass = `${baseTextClass} breadcrumb dark:dark-breadcrumb`;
  const dividerClass = `${baseTextClass} mx-2`;
  const searchItemClass = `${baseTextClass}`;

  // Determine what to render for the title
  let content;
  if (active) {
    content = <span className={breadcrumbClass}>{title.replace('cloud', 'Cloud')}</span>;
  } else if (isSearchItem) {
    content = <div className={searchItemClass}>{title.replace('cloud', 'Cloud')}</div>;
  } else {
    content = (
      <Link to={url} className={baseTextClass}>
        {title.replace('cloud', 'Cloud')}
      </Link>
    );
  }

  return (
    <div className="flex flex-row">
      <div>{content}</div>
      {!active && (
        <div>
          <span className={dividerClass}>{"/"}</span>
        </div>
      )}
    </div>
  );
};

export default BreadCrumb;
