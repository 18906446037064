import { titlePerPath } from "../const";
import { Guide, MainPath } from "../types/types";
import { getDocTitleSlug } from "./getDocTitleSlug";
import { getDocVersionSlug } from "./getDocVersionSlug";
import { normalizePermalink } from "./normalizePermalink";

// TODO: query this info?
export const notFoundUrl = "/404/";

export type RootPageParams = {
  path: "root";
};

export type NotFoundPageParams = {
  path: "not-found";
};

interface DocPageParams {
  path: string;
  version?: string;
  latestVersion: string;
  cloudVersion?: string;
  language?: string;
  permalink: string;
  title?: string;
  hash?: string;
  forceVersion?: boolean;
  slug?: string;
}

export type ReleaseNotesPageParams = {
  path: "release_notes";
  language?: string;
  slug: string;
  hash?: string;
};

export type BuildAppUrlParams =
  | RootPageParams
  | NotFoundPageParams
  | DocPageParams
  | ReleaseNotesPageParams;


  //default unspecified versions to cloud
  export function buildDocPageUrl({
    version = "cloud",
    latestVersion,
    cloudVersion = "cloud",
    language = "en",
    path,
    permalink,
    title,
    hash = "",
    forceVersion = false,
  }: DocPageParams): string {
    const versionSlug = getDocVersionSlug(version, {
      latestVersion,
      cloudVersion,
      forceVersion,
    });
    const titleSlug = getDocTitleSlug(title);
    
    // Ensure proper URL construction with version handling
    let urlVersion = versionSlug;
    if (version === "cloud") {
      urlVersion = "cloud";  // Always use lowercase for URLs
    } else if (version === "latest") {
      urlVersion = "latest";
    }
    
    return encodeURI(
      `/${language}/${urlVersion}/${path}/${permalink}/${titleSlug}/${hash}`
    );
  }


export function buildReleaseNotesPageUrl({
  slug,
  hash = "",
}: ReleaseNotesPageParams): string {
  const url = encodeURI(`${slug}${hash}`);

  // The returned URL must start with "/release_notes".
  //
  const index = url.indexOf("/release_notes");
  if (index === -1) {
    // Not found.
    return url;
  }

  return url.substring(index);
}

export function buildAppUrl(params: BuildAppUrlParams): string {
  if (params.path === "release_notes") {
    return buildReleaseNotesPageUrl(params as ReleaseNotesPageParams);
  } else if (params.path === "root") {
    return "/";
  } else if (params.path === "not-found") {
    return notFoundUrl;
  } else {
    return buildDocPageUrl(params as DocPageParams);
  }
}

export function buildGuideIndexUrl({
  guide,
  version,
  latestVersion,
  language,
  hash,
  forceVersion,
}: {
  guide: Guide;
  version?: string;
  latestVersion: string;
  language?: string;
  hash?: string;
  forceVersion?: boolean;
}) {
  return buildAppUrl({
    latestVersion,
    permalink: normalizePermalink({ guide }),
    title: titlePerPath[guide],
    path: guide,
    version,
    language,
    hash,
    forceVersion,
  });
}

export function buildReleaseNotesIndexUrl({ hash }: { hash?: string }): string {
  return buildReleaseNotesPageUrl({
    path: "release_notes",
    slug: "/release_notes/",
    hash,
  });
}

export function buildPathIndexUrl({
  language,
  hash,
  latestVersion,
  version,
  forceVersion,
  path,
}: {
  language?: string;
  hash?: string;
  version?: string;
  latestVersion: string;
  forceVersion?: boolean;
  path: MainPath;
}) {
  if (path === "release_notes") {
    return buildReleaseNotesIndexUrl({
      hash,
    });
  } else {
    return buildGuideIndexUrl({
      guide: path,
      latestVersion,
      forceVersion,
      hash,
      language,
      version,
    });
  }
}
