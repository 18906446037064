import React, { ReactNode, useContext } from "react";
import { DocOverview } from "../templates/appPage";
import Navbar from "./navbar";
import MainContent from "./mainContent";
import Sidebar from "./sidebar/sidebar";
import { isSearchScreen } from "../utils/screenRouteChecks";
import { useLocation } from "../contexts/useLocation";
import FacetsMenu from "./search_screen/facetsMenu";
import { ResponsiveContext } from "../contexts/ResponsiveContext";
import { Layout as AntLayout } from "antd";

const Layout = ({
  children,
  root,
  version,
  categoryVersions,
  title,
  permalink,
  id,
}: {
  children: ReactNode;
  title: string | undefined;
  permalink: string;
  root: DocOverview | undefined;
  version: string;
  categoryVersions: string[];
  id: string | undefined;
}) => {
  const docs = root?.children ?? [];
  const location = useLocation();
  const hasRoot = root !== undefined;
  const { responsiveState } = useContext(ResponsiveContext);
  const isSearch = isSearchScreen(location.pathname);

  return (
    <AntLayout>
      {hasRoot && docs && (
        <Sidebar
          categoryVersions={categoryVersions}
          docs={docs}
          version={version}
          title={title ? title : ""}
          permalink={permalink}
        />
      )}
      {isSearch &&
        !responsiveState.isSidebarActive &&
        !responsiveState.isMenuActive && <FacetsMenu />}
      <AntLayout
        className={`${((hasRoot && docs) || isSearch) && "main-content"} `}
        id={`permalink-${permalink}`}
      >
        <div className="layout">
          <Navbar version={version ? version : "latest"} />
          <MainContent
            permalink={permalink}
            root={root}
            version={version}
            title={title}
            id={id}
            categoryVersions={categoryVersions}
          >
            {children}
          </MainContent>
        </div>
      </AntLayout>
      {/* <Footer /> */}
    </AntLayout>
  );
};

export default Layout;
