import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { useCurrentPath } from "../../contexts/useCurrentPath";
import { useSiteMetadataVersions } from "../../queries/useSiteMetadataVersions";
import { DocOverview } from "../../templates/appPage";
import { getUrlForChangedVersion } from "./sidebar.utils";
import documentationLogo from "../../images/domino-logo-large.png";
import { MainPath } from "../../types/types";
import { buildAppUrl } from "../../utils/buildAppUrl";
import getDisplayVersion from "../../utils/getDisplayVersion";
import { useLocation } from "../../contexts/useLocation";
import { Layout, Menu, Select } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { RightOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import CircleTooltip from "./circleTooltip";
import TextTooltip from "./textTooltip";

import { WindowLocation } from "@reach/router";
import { compareVersions } from "../../utils/getIntegralVersion";
import { useVersionLookup } from "../../utils/getVersionLookup";

const { Sider } = Layout;

const backgroundColorActive = `#111A20`;
const backgroundColorInActive = `#111A20`;
type Props = {
  docs: DocOverview[];
  version: string;
  categoryVersions: string[];
  permalink: string;
  title: string;
};

const getLinkWithPath = (
  doc: DocOverview,
  url: string,
  isLocationPathAndUrlEqual: boolean,
) => (
  <Link
    key={doc.id}
    to={url}
    style={{
      width: "100%",
      display: "block",
    }}
    id={`${isLocationPathAndUrlEqual && "active-menu-item"}`}
    {...{
      version: doc.version,
    }}
  >
    <span>{doc.sidebar ? doc.sidebar : doc.title}</span>
  </Link>
);

type SubMenuItemProps = {
  doc: DocOverview;
  currentPath: MainPath;
  version: string;
  latestVersion: string;
  location: WindowLocation;
};

const getSubMenuItems = (props: SubMenuItemProps): ItemType => {
  const { doc, latestVersion, version, currentPath, location } = props;
  const url = buildAppUrl({
    latestVersion,
    permalink: doc.permalink,
    slug: doc.slug,
    title: doc.title,
    version,
    path: currentPath,
  });
  const isLocationPathAndUrlEqual = location.pathname === url;
  if (doc.children.length === 0) {
    return {
      style: isLocationPathAndUrlEqual
        ? { backgroundColor: backgroundColorActive }
        : { backgroundColor: backgroundColorInActive },
      key: doc.id,
      label: getLinkWithPath(doc, url, isLocationPathAndUrlEqual),
    };
  } else {
    return {
      style: isLocationPathAndUrlEqual
        ? { backgroundColor: backgroundColorActive }
        : { backgroundColor: backgroundColorInActive },
      key: doc.id,
      label: getLinkWithPath(doc, url, isLocationPathAndUrlEqual),
      children: getMenuItemChildren({
        doc,
        currentPath,
        version,
        latestVersion,
        location,
      }),
    };
  }
};

const getMenuItemChildren = (props: SubMenuItemProps): ItemType[] => {
  const { doc: docs, latestVersion, version, currentPath, location } = props;
  return docs.children.map((doc) =>
    getSubMenuItems({ doc, currentPath, version, latestVersion, location }),
  );
};

type MenuItemProps = {
  docs: DocOverview[];
  currentPath: MainPath;
  version: string;
  latestVersion: string;
  location: WindowLocation;
};

const getMenuItems = (props: MenuItemProps): ItemType[] => {
  const { docs, latestVersion, version, currentPath, location } = props;
  return docs.flatMap((doc, index) => {
    const items: ItemType[] = []; // Create an array to hold items for this iteration

    let sectionHeader: ItemType | null = null; // Variable to hold the section header
    if (doc["section"]) {
      sectionHeader = {
        key: `section-${index}`, // Unique key for each section header
        label: (
          <span
            className={`section-span ${doc["separator"] === "true" ? "border-t border-faint-border" : ""
              }`}
          >
            {doc["section"]} {/* Display the section title */}
          </span>
        ),
        className: "section-header",
        disabled: true
      };

      items.push(sectionHeader); // Add the section header to the items array
    }

    const item = getSubMenuItems({
      doc,
      currentPath,
      version,
      latestVersion,
      location,
    });

    // If there is no section but a separator, add a bottom border to the menu item
    if (!sectionHeader && doc["separator"] === "true" && item !== null) {
      item["className"] = "border-b border-faint-border"; // Add a bottom border
    }

    if (item !== null) {
      items.push(item); // Add the item to the array
    }

    return items;
  });
};

const Sidebar = (props: Props) => {
  const { categoryVersions, version, docs, permalink, title } = props;
  const { dispatch } = useContext(ResponsiveContext);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [docIdsKeyValuePair, setDocIdsKeyValuePair] = useState<any>();
  const [selectedKey, setSelectedKey] = useState<string>();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const location = useLocation();
  const versionLookup = useVersionLookup();  // Move this up
/*   const isCloudVersion = location.pathname.startsWith('/en/cloud/');
  const currentVersion = isCloudVersion ? 'cloud' : version; */

  const currentPath = useCurrentPath();
  const { latestVersion } = useSiteMetadataVersions();

/*   useEffect(() => {
    console.log("Version details:", {
      currentVersion: version,
      availableVersions: categoryVersions,
      highestVersion: categoryVersions
        .filter(v => v !== 'cloud')
        .sort((a, b) => compareVersions(b, a))[0]
    });
  }, [version, categoryVersions]); */

  const changeSelectedVersion = (version: string) => {
    if (currentPath == null) return;
    if (currentPath === "release_notes") return;
    const url = getUrlForChangedVersion({
      latestVersion: latestVersion,
      version: version,
      title: title,
      currentPath: currentPath,
      permalink: permalink,
      versionLookup: versionLookup,
    });
    navigate(url);
  };

  let docIds: any = {};
  const subMenuIds = (subDoc: DocOverview) => {
    subDoc.children.map((doc) => {
      docIds = { ...docIds, [doc.id]: `${docIds[subDoc.id]}/${doc.id}` };
      if (!(doc.children.length === 0)) {
        subMenuIds(doc);
      }
    });
  };

  const fetchIdsOfDocs = () => {
    docs.map((doc) => {
      docIds = { ...docIds, [doc.id]: doc.id };
      if (!(doc.children.length === 0)) {
        subMenuIds(doc);
      }
    });
    setDocIdsKeyValuePair(docIds);
  };

  const checkSubMenuItems = (doc: DocOverview) => {
    const url = buildAppUrl({
      latestVersion,
      permalink: doc.permalink,
      slug: doc.slug,
      title: doc.title,
      version,
      path: currentPath as MainPath,
    });
    const isLocationPathAndUrlEqual = location.pathname === url;
    if (isLocationPathAndUrlEqual) {
      setSelectedKey(doc.id);
    }
    if (!(doc.children.length === 0)) {
      checkMenuItems(doc);
    }
  };

  const checkMenuItems = (subDocs: DocOverview) => {
    subDocs.children.map((doc) => checkSubMenuItems(doc));
  };

  const fetchSelectedKeyByPath = () => {
    docs.map((doc) => checkSubMenuItems(doc));
  };

  useEffect(() => {
    fetchIdsOfDocs();
    fetchSelectedKeyByPath();
  }, [version, title]);

  // This is a fix for no global state to check guides
  // This resets the selected menu items when the guide changes
  useEffect(() => {
    const pathNameArray = location.pathname.split("/")
    const guideFromPath = pathNameArray[pathNameArray.length - 2]
    if (guideFromPath == "admin-guide" || guideFromPath == "api-guide" || guideFromPath == "user-guide") {
      setOpenKeys(
        []
      );
      setSelectedKey("");
    }
  }, [currentPath]);

  useEffect(() => {
    if (selectedKey && docIdsKeyValuePair) {
      setOpenKeys(
        docIdsKeyValuePair[selectedKey] &&
        docIdsKeyValuePair[selectedKey].split("/"),
      );
    }
  }, [docIdsKeyValuePair, selectedKey]);

  const onOpenChange = (keys: string[]) => {
    const safeOpenKeys = Array.isArray(openKeys) ? openKeys : [];
    const latestOpenKey = keys.find((key) => safeOpenKeys.indexOf(key) === -1);
    const rootSubmenuKeys = docs.map((doc) => doc.id);
    // @ts-ignore
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onMenuClick = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const items = useMemo(() => {
    return getMenuItems({
      docs,
      currentPath: currentPath as MainPath,
      version,
      latestVersion,
      location,
    });
  }, [docs, currentPath, version, latestVersion, location]);

  function versionCompare(a: any, b: any) {
    if (!a?.label?.props || !b?.label?.props) return 0;
    const propsA = a.label.props;
    const propsB = b.label.props;
    
    // Safe navigation for path comparison
    const pathA = propsA?.to || '';
    const pathB = propsB?.to || '';
    
    if (pathA === "/release_notes/preview-features/") return -1;
    if (pathB === "/release_notes/preview-features/") return +1;
    
    // Only compare versions if both exist
    if (propsA?.version && propsB?.version) {
      return compareVersions(propsB.version, propsA.version);
    }
    return 0;
  }

  const getSortedItems = (items: ItemType[], currentPath: string | null) => {
    if (currentPath !== "release_notes") return items;
    return [...items].sort(versionCompare);
  };

  const sortedItems = useMemo(() => {
    return getSortedItems(items, currentPath);
  }, [items, currentPath]);

  /* const sorted = currentPath == "release_notes" ? [...items].sort(versionCompare) : items;

  
 */
  return (
    <Sider
      className={`ant-sider sticky ${collapsed ? "ant-layout-sider-collapsed" : "ant-sider-collapse-menu"
        }`}
      style={{
        left: 0,
        top: 0,
        bottom: 0,
        position: "fixed",
      }}
      breakpoint="md"
      collapsedWidth={0}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      width={300}
      collapsed={collapsed}
    >
      <div className={`ml-8 mt-4 md:ml-8 md:mt-4 h-8 w-36 bg-very-dark-grey  `}>
        <Link to="/">
          <img
            loading="lazy"
            src={documentationLogo}
            className="!my-0 !mx-0 lg:!mx-0 w-full cursor-pointer nav-logo"
            alt="domino logo"
            id="domino-logo"
          />
        </Link>
      </div>
      <div className="sidebar-leftnav" id="leftnav">
        <div className="flex items-center space-x-2">

          <div className="version-selection">
            {currentPath && currentPath !== "release_notes" && (
              <Select
                value={version}
                getPopupContainer={(trigger) => trigger.parentElement}
                onChange={(event) => {
                  changeSelectedVersion(event);
                  dispatch({ type: "closeSidebar" });
                }}
              >
                {categoryVersions.map((v) => (
                <Select.Option key={v} value={v}>
                  {getDisplayVersion(v, latestVersion)}
                </Select.Option>
              ))}
              </Select>
            )}

          </div>
          {currentPath && currentPath !== "release_notes" && (
            <CircleTooltip
              text="Click here to view older versions on our archive site"
              link="https://archive.docs.dominodatalab.com/"
            />
          )}
        </div>

        <Menu
          theme="dark"
          mode="inline"
          // @ts-ignore - Ignore the component injection error.
          expandIcon={<RightOutlined />}
          style={{
            borderRight: 0,
          }}
          items={sortedItems}
          openKeys={openKeys}
          defaultOpenKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={({ keyPath }) => onMenuClick(keyPath)}
        />
        {currentPath && currentPath == "release_notes" && (
          <div className="flex flex-col items-start align-middle pt-10 pl-6">
            <TextTooltip
              text="Older versions"
              tooltipText="Older release notes can be found on our archive site"
              link="https://archive.docs.dominodatalab.com/release_notes"
            />
          </div>

        )}
      </div>

    </Sider>
  );
};

export default Sidebar;
