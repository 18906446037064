import React, { useEffect } from "react";
import { Link, LinkProps } from "react-scroll"; // See https://www.npmjs.com/package/react-scroll.
import { useHash } from "../contexts/useHash";

export type ScrollLinkProps = Omit<
  LinkProps,
  "to" | "href" | "spy" | "onSetActive"
> & {
  toId: string;
  onSetActive?(): void;
};
const _ScrollLink = (props: LinkProps) => {
  // for some reason LinkProps are not compatible with Link directly
  // something about legacy ref being incompatible :/
  // So making this wrapper to ditch the ref.
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Link {...(props as any)} />;
};

const ScrollLink = ({
  smooth = true,
  hashSpy = true,
  duration = 300,
  onSetActive,
  onSetInactive,
  toId,
  ...props
}: ScrollLinkProps) => {
  // react-scroll spy behavior can be overlapping if divs overlap
  // so here we ensure only one link is active by relying on current hash
  // url instead of scroll position.

  // useLocation is not updated when hash is changed, so using useHash instead
  const hash = useHash();

  const href = `#${toId}`;
  const isActive = hash === href;

  useEffect(() => {
    if (isActive) {
      onSetActive?.();
    } else {
      // @ts-ignore
      onSetInactive?.();
    }
  }, [isActive]);

  return (
    <_ScrollLink
      {...props}
      smooth={smooth}
      hashSpy={hashSpy}
      duration={duration}
      to={toId}
      href={href}
    />
  );
};

export default ScrollLink;
