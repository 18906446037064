import { Link } from "gatsby";
import React, { useContext } from "react";
import { ResponsiveContext } from "../../contexts/ResponsiveContext";
import { useCurrentPath } from "../../contexts/useCurrentPath";
import { MainPath } from "../../types/types";
import { useLocation } from "../../contexts/useLocation";
import { isHomeScreen } from "../../utils/screenRouteChecks";

type Props = {
  path: MainPath;
  label: string;
  route: string;
};

const NavbarButton = ({ path, label, route }: Props) => {
  const { responsiveState, dispatch } = useContext(ResponsiveContext);
  const currentPath = useCurrentPath();
  const { pathname } = useLocation();

  const closeMenu = () => {
    if (responsiveState.isMenuActive) {
      dispatch({ type: "toggleMenu" });
    }
  };

  return (
    // TODO: use Link getProps instead of currentPath
    <Link to={route}>
      <button
        type="button"
        className={
          isHomeScreen(pathname)
            ? "landing-navbar-button"
            : currentPath === path
            ? "active-navbar-button"
            : "navbar-button"
        }
        onClick={closeMenu}
      >
        {label}
      </button>
    </Link>
  );
};

export default NavbarButton;
