import { buildSearchEngine, getOrganizationEndpoints } from "@coveo/headless";

const COVEO_ORGANISATION_ID = process.env.COVEO_ORGANISATION_ID || null;
const COVEO_ACCESS_TOKEN = process.env.COVEO_ACCESS_TOKEN || null;
const COVEO_SOURCE = process.env.COVEO_SOURCE || null;

const areCoveoVariablesDefined = Boolean(
  COVEO_ORGANISATION_ID && COVEO_ACCESS_TOKEN && COVEO_SOURCE,
);
// TODO: put access token into .env
export const headlessEngine = areCoveoVariablesDefined
  ? buildSearchEngine({
      configuration: {
        organizationId: COVEO_ORGANISATION_ID!,
        accessToken: COVEO_ACCESS_TOKEN!,
        organizationEndpoints: getOrganizationEndpoints(COVEO_ORGANISATION_ID!),
        preprocessRequest: (request, clientOrigin) => {
          if (clientOrigin === "searchApiFetch") {
            if (typeof request.body === "string") {
              const body = JSON.parse(request.body);

              body.cq = `@source=="${COVEO_SOURCE}"`;

              // TODO - Further investigate the functionality of coveo.
              // for now this cleans up console and does not break if the object does not exist.
              if (body.fieldsToInclude != null) {
                body.fieldsToInclude.push("breadcrumbs");
                body.fieldsToInclude.push("docsguide");
                body.fieldsToInclude.push("docsversion");
              }

              request.body = JSON.stringify(body);
            }
          }
          return request;
        },
        search: {
          pipeline: "Customer",
        },
      },
    })
  : {
      // Mock or fallback implementation
      search: () => {
        console.warn("Coveo search engine is not initialized. Using fallback.");
        return Promise.resolve({ results: [], totalCount: 0 });
      },
    };
