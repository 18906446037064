import React, { createContext, useContext} from "react";
import { MainPath } from "../types/types";

const initialState: MainPath | null = null;

const CurrentPathContext = createContext<MainPath | null>(initialState);

export function CurrentPathProvider({
  currentPath,
  children,
}: {
  currentPath: MainPath | null;
  children?: React.ReactNode;
}) {

  return (
    <CurrentPathContext.Provider value={currentPath}>
      {children}
    </CurrentPathContext.Provider>
  );
}

export function useCurrentPath(): MainPath | null {
  return useContext(CurrentPathContext);
}
