export function getDocVersionSlug(
  version: string,
  {
    latestVersion,
    cloudVersion,
    forceVersion = false,
  }: {
    latestVersion: string;
    cloudVersion: string;
    forceVersion?: boolean;
  }
): string {
  if (version === "cloud") {
    return cloudVersion;
  }
  
  if (!forceVersion && version === latestVersion) {
    return "latest";
  }
  
  return version;
}