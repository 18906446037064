import React from "react";
import { adocSectionIdDataAttribute } from "../../const";
import TocMenuItem from "./TocMenuItem";

type Props = {
  elements: Element[];
};

const TocMenu = ({ elements }: Props) => {
  // Retain only link items.
  elements = elements.filter(element => element.getAttribute(adocSectionIdDataAttribute) !== null);

  return (
    <div className="toc-menu-container hidden lg:block pb-24">
      {elements.length > 0 && (
      <div className="mt-4 bg-white dark:bg-very-dark-grey pt-4">
        <h2 className="font-semibold text-xs dark:text-soft-blue-grey">ON THIS PAGE</h2>
        {elements.map((element) => (
          <TocMenuItem
            key={element.getAttribute(adocSectionIdDataAttribute)}
            element={element}
          />
        ))}
      </div>
      )}
    </div>
  );
};
export default TocMenu;
