import { graphql, useStaticQuery } from "gatsby";

export const useVersionLookup = () => {
  const data = useStaticQuery<{
    allAsciidoc: {
      nodes: Array<{ permalink: string; version: string; title: string }>;
    };
  }>(graphql`
    query {
      allAsciidoc {
        nodes {
          permalink
          version
          title
        }
      }
    }
  `);

  const versionLookup: {
    [key: string]: { versions: string[]; titles: string[] };
  } = {};

  for (const node of data.allAsciidoc.nodes) {
    if (!Object.prototype.hasOwnProperty.call(versionLookup, node.permalink)) {
      versionLookup[node.permalink] = { versions: [], titles: [] };
    }
    if (!versionLookup[node.permalink].versions.includes(node.version)) {
      versionLookup[node.permalink].versions.push(node.version);
      versionLookup[node.permalink].titles.push(node.title);
    }
  }

  return versionLookup;
};