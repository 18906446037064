import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";

const TextTooltip = ({ text, tooltipText, link }: { text: string; tooltipText: string; link: string }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const triggerRef = useRef<HTMLAnchorElement>(null);

  // Handle mouse and touch events
  const showTooltip = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setTooltipStyle({
        top: (rect.top - (rect.height / 2) + 4 ) , // Position below the trigger with a small offset
        left: rect.left + rect.width + 4, // Center horizontally
        /* transform: "translateX(30%)", */ // Adjust for centering
      });
    }
    setIsVisible(true);
  };

  const hideTooltip = () => setIsVisible(false);

  return (
    <div className="relative group" >
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className=" h-6 p-2 flex items-center justify-center border-2 border-orange-500 text-orange-500 text-sm rounded-full cursor-pointer hover:text-blue-500 hover:border-blue-500"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onTouchStart={showTooltip} // Touch functionality for mobile
        onTouchEnd={hideTooltip} // Hide tooltip after touch ends
        ref={triggerRef}
      >
        {text}
      </a>
      {isVisible &&
        createPortal(
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="fixed min-w-3xs max-w-3xs z-9999"
            style={{
              ...tooltipStyle,
            }}
          >
            <div className="bg-admonition-warning-orange border-admonition-warning-orange text-bright-white text-sm font-medium p-3 rounded-md shadow-md whitespace-normal">
              {tooltipText}
            </div>
          </a>,
          document.body // Render the tooltip in the body
        )}
    </div>
  );
};

export default TextTooltip;